import './page-title';
import './web-components';
import './theme';
import '../styles/main.css';

document.addEventListener('keydown', (event) => {
    // overriding print behaviour so that we can print main frame content only
    if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        window.print();
    }
});
