interface WT extends Window {
    colors: {
        purchase: string;
        sales: string;
    };
}

document.addEventListener('DOMContentLoaded', () => {
    if (window.frames.name.startsWith('fancy') || window.frames.name.startsWith('react-iframe')) {
        const getElementCreated = (styles: null | Partial<CSSStyleDeclaration> = null) => {
            const dynamicPageTitle = document.getElementById('dynamicPageTitle');
            const topFrame = window.top as WT;
            const { purchase, sales } = topFrame.colors;
            const getTitleColor = () => {
                if (dynamicPageTitle!.querySelector('.inkoopkleur')) {
                    return purchase;
                }
                if (dynamicPageTitle!.querySelector('.verkoopkleur')) {
                    return sales;
                }
                return '';
            };
            const newElement = document.createElement('div');
            newElement.innerHTML = null === dynamicPageTitle ? '' : dynamicPageTitle.innerHTML;
            newElement.className = 'fancyPageTitle';
            newElement.id = 'fancyPageTitle';
            newElement.style.backgroundColor = getTitleColor();
            if (null !== styles) {
                Object.assign(newElement.style, styles);
            }
            return newElement;
        };
        const el = window.parent.document.getElementsByClassName('fancybox-wrap fancybox-desktop')[0];
        const exitingTitle = window.parent.document.getElementById('fancyPageTitle');
        if (exitingTitle !== undefined) {
            exitingTitle?.remove();
        }

        el?.prepend(getElementCreated());
        if (window.frames.name.startsWith('react-iframe')) {
            const iframeDialog = window.parent.document.getElementsByClassName('iframe-dialog')[0];
            const elementStyles = {
                position: 'absolute',
                top: '-40px',
            };
            iframeDialog?.prepend(getElementCreated(elementStyles));
        }
    }
});
